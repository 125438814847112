import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import * as moment from 'moment';
import { CustomerService } from 'services/customer-service';

@autoinject
export class StorageInvoiceFilters {

	@bindable private filters: any;
	@bindable private getQuery;
	@bindable private context;
	@bindable private fields;
	@bindable private pageSize;
	@bindable private getData;
  @bindable private toggleVisible;
  @bindable private getKey;
  @bindable private isNetList;
  @bindable private customerId;
  @bindable private clearAll;

  private contextWithAllStatuses: Array<string> = ['service-invoicing-report', 'service-antifouling-report', 'service-repairs-report', 'statistics', 'customer-services', 'net-services'];

	public filterTypes = {
		CHECKBOX: 'checkbox',
		RADIO: 'radio',
		RADIO_SIMPLE: 'radioSimple',
		RANGE: 'range',
		RANGE_DATE: 'rangeDate',
	};

	private filterInputInFocus: object = {};
	private filterDatepickerInFocus: any;

	constructor(
    private customerService: CustomerService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
	) {

	}

	private attached() {
    /* this.setupFilters(); */

		this.getQuery = this.filterGetFiltersQuery.bind(this);
		this.toggleVisible = this.filterToggleVisible.bind(this);
		this.clearAll = this.clearAllFilters.bind(this);
	}

	// Helper-function for filters
  private filterValuesToArray(filter) {
    
    if (!filter?.values || Object.keys(filter?.values).length == 0) {
      return null;
    }
    
    const values = Object.entries(filter?.values);
    const data = values?.map((it) => {

      const item = {
        key: it[0],
        value: it[1],
        object: null
      }

      const objectRef = filter.options.find((it) => it?.Id == item.key);
      if (objectRef) {
        item.object = objectRef;
      }

      return item;

    });
    
    return data;
  }

  private i18nName(row) {
    if (!row) {
      return null;
    }

    const lang = this.i18n.getLocale();
    switch (lang) {
      case 'nb-NO':
        return row['Name'];
      case 'en':
        return row['NameEn'] ? row['NameEn'] : row['Name'];
      case 'es':
        return row['NameEs'] ? row['NameEs'] : row['NameEn'];
      default:
        return row['Name'];
    }
  }

  // Sets the selected-label that shows in the dropdown
  // Converts array to compact string etc.
  private async setFilterValueLabel(filter) {

    // Checkbox
    if (filter.type === this.filterTypes.CHECKBOX) {
      
      const values: any =
        this.filterValuesToArray(filter)
        ?.filter((it) => it.value == true)
        ?.sort((a, b) => {
          if (a?.object?.SortIndex && b?.object?.SortIndex) {
            return a.object.SortIndex > b.object.SortIndex ? 1 : -1;
          } else {
            return 0;
          }
        });

      const fullString = values?.map((it: any) => this.i18nName(it.object)).join(', ');
      
      if (!values || values.length == 0) {
        filter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = true;
      } else if (values.length == 1) {
        filter._valueLabel = fullString.substring(0, 16);
        filter._valueIsNone = false;
      } else if (values.length > 1 && fullString.length > 16) {
        filter._valueLabel = `${fullString.substring(0, 16)} ...`;
        filter._valueIsNone = false;
      } else {
        filter._valueLabel = fullString.substring(0, 16);
        filter._valueIsNone = false;
      }
    }

    // Range
    if (filter.type === this.filterTypes.RANGE) {
      if (filter.values.from && filter.values.to) {
        filter._valueLabel = `${filter.values.from} - ${filter.values.to}`;
        filter._valueIsNone = false;
      } else if (filter.values.from) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${filter.values.from}`;
        filter._valueIsNone = false;
      } else if (filter.values.to) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${filter.values.to}`;
        filter._valueIsNone = false;
      } else {
        filter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = true;
      }
    }

    // Range Date
    if (filter.type === this.filterTypes.RANGE_DATE) {

      if (filter.values.from && filter.values.to) {
        filter._valueLabel = `${moment.utc(filter.values.from).format('DD.MM.YYYY')} - ${moment.utc(filter.values.to).format('DD.MM.YYYY')}`;
        filter._valueIsNone = false;
      } else if (filter.values.from) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${moment.utc(filter.values.from).format('DD.MM.YYYY')}`;
        filter._valueIsNone = false;
      } else if (filter.values.to) {
        filter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${moment.utc(filter.values.to).format('DD.MM.YYYY')}`;
        filter._valueIsNone = false;
      } else {
        filter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = true;
      }
    }

    // Radio simple
    if (filter.type === this.filterTypes.RADIO_SIMPLE) {
      const selected = filter.options.find(({ value }) => value === filter.values);
      filter._valueLabel = selected.label;
    }

    // Radio
    if (filter.type === this.filterTypes.RADIO) {
      setTimeout(() => {
        const selected = filter.options.find(({ Id }) => Id === filter.values);
        filter._valueLabel = this.i18nName(selected) ?? this.i18n.tr('servicefilters.noneSelected');
        filter._valueIsNone = this.i18nName(selected) ? false : true;
      });
    }

  }

  // Sets the _selectedOptions on applicable filters
  // This array is just a helper for the frontend-logic and repeaters
  private setFilterSelectedOptions(filter) {
    if (filter.type === this.filterTypes.CHECKBOX) {

      const selected = filter.options.filter((option) => {
        return (filter.values[option.Id]);
      });

      filter._selectedOptions = selected;

    }
  }

  private async setFiltersDisabled() {
    for (const [key, filter ] of Object.entries(this.filters) as any) {
      if (typeof filter === 'object') {
        if (filter.disabled !== undefined) {

          if (typeof filter.disabled === 'function') {
            this.filters[key]._disabled = filter.disabled(this.filters);
          } else {
            this.filters[key]._disabled = filter.disabled;
          }

          this.eventAggregator.publish('filterOptionsRefresh', key);

        }    
      }  
    }
  }

  private async filterGetFiltersQuery(fields = null) : Promise<object> {
    return new Promise(async (resolve, reject) => {

			if (!this.filters) {
				await this.setupFilters();
			}
      
      const query: object = {};

      const valuesToArray = (filter) => {
        if (filter.type === this.filterTypes.CHECKBOX) {
          return Object.entries(filter?.values).filter(([key, value]) => !!value).map(([key]) => key) ?? [];
        } else {
          return filter?.values;
        }
      }
      
      const filters: Array<[string, any]> = Object.entries(this.filters);
      for (let [filterKey, filter] of filters) {
        if (!filter || typeof filter !== 'object') {
          continue;
        }

        // Has custom query
        if (filter.query) {
          
          // Servicefilter uses custom query-key
          if (typeof filter.query === 'string') {
            const values = valuesToArray(filter);
            if (values?.length > 0) {
              query[filter.query] = valuesToArray(filter);
            }
          }

          // Servicefilter uses custom query-function
          else if (typeof filter.query === 'function') {

            // Multiple values i returned
            const values = filter.query(filter.values);
            if (Array.isArray(values)) {
              if (values.length > 0) {
                values.forEach(({ key, value }) => {
                  query[key] = value;
                });
              }
            }
            
            // Single value is returned
            else {
              if (values.value && values.value !== '') {
                query[values.key] = values.value;
              }
            }

          }

        }

        // Use default query-handling
        else {

          // Range
          if (filter.type === this.filterTypes.RANGE) {
            query[`${filter.name}From`] = filter.values.from;
            query[`${filter.name}To`] = filter.values.to;
          }

          // Range Date
          if (filter.type === this.filterTypes.RANGE_DATE) {
            query[`${filter.name}From`] = filter.values.from ? moment.utc(filter.values.from).format('YYYY-MM-DD') : null;
            query[`${filter.name}To`] = filter.values.to ? moment.utc(filter.values.to).format('YYYY-MM-DD') : null;
          }

          // Radio simple
          if (filter.type === this.filterTypes.RADIO_SIMPLE) {
            if (filter.values !== null) {
              query[filter.name] = filter.values;
            }
          }

          // Radio
          if (filter.type === this.filterTypes.RADIO) {
            query[filter.name] = filter.values;
          }

        }

      }

      // Misc
      query['_select'] = fields ? fields?.filter((x) => x.selected && !x.function).map((x) => x.field).join(',') : this.fields?.filter((x) => x.selected && !x.function).map((x) => x.field).join(',');
      query['_export'] = false;
      query['top'] = this.pageSize;

      return resolve(query);

    });
  }
  
  // Radio-buttons needs a middle-function because of some
  // binding-issues, and because the click-event has to
  // get a true in return
  private filterRadioChangeProxy(filter, option, event) {
    setTimeout(() => {
      this.filterChange(filter, option, event);
    });
    return true;
  }

  // Datepickers need a middle-function to better handle changes
  private filterDateChangeProxy(filter, option, event) {

    if (!event.firedBy) {
      return this.filterChange(filter, option, event);
    }

    const date = event.firedBy._d;
    if ((date && filter?.values[option] && moment.default(date).format('YYYY-MM-DD') == moment.default(filter?.values[option]).format('YYYY-MM-DD'))) {
      return;
    }

    // hack because datepicker is slow
    setTimeout(() => {
      this.filterChange(filter, option, event);
    }, 500);

  }

  private async filterChange(filter, option, event) {

    // Filter has custom change-function
    if (filter.change) {
      filter.change(option, event, filter.values);
    }

    // Use default change-functions
    else {

      if (filter.type === this.filterTypes.CHECKBOX) {
        filter.values[option.Id] = event.target.checked;
        filter._clearable = Object.values(filter.values).includes(true);
      }

      if (filter.type === this.filterTypes.RANGE) {
        filter._clearable = (filter.values.from || filter.values.to);
      }

      if (filter.type === this.filterTypes.RANGE_DATE) {
        filter.values[`${filter.name}${option}`] = event.detail;
        filter._clearable = (filter.values.from || filter.values.to);
      }

      if (filter.type === this.filterTypes.RADIO_SIMPLE) {
        this.filters[filter.name].values = option.value;
        filter._clearable = (filter.values !== null);
      }

      if (filter.type === this.filterTypes.RADIO) {
        this.filters[filter.name].values = option.Id;
        filter._clearable = (filter.values !== null);
      }

    }

    this.setFilterSelectedOptions(filter);
    this.setFilterValueLabel(filter);
    this.setFiltersDisabled();
    this.getData();
    this.filtersSaveLocal();

  }

  private async filterClearSingle(filter) {
    await this.filterClear(filter, true);
    await this.getData();
  }

  /**
   * Will clear a filter to a null-state
   * This will _not_ reset a filter to default value
   * @param filter 
   * @param useDefaults Will ignore a filter's custom clear-function if set to `true`
   */
  private async filterClear(filter, useDefaults = false, batch = false) {
    if (!filter || typeof filter !== 'object') {
      return;
    }

    // Filter has custom clear-function
    if (filter.clear && !useDefaults) {
      filter.clear(filter.values);
    }

    // Use default clear-functions
    else {

      // Checkbox
      if (filter.type === this.filterTypes.CHECKBOX) {
        for (const [key, value] of Object.entries(filter.values)) {
          filter.values[key] = false;
        }
      }

      // Radio
      if (filter.type === this.filterTypes.RADIO) {
        filter.values = null;
      }

      // Radio simple
      if (filter.type === this.filterTypes.RADIO_SIMPLE) {
        filter.values = null;
      }

      // Range
      if (filter.type === this.filterTypes.RANGE) {
        filter.values.from = null;
        filter.values.to = null;
      }

      // Range Date
      if (filter.type === this.filterTypes.RANGE_DATE) {
        /* filter.values.from = null;
        filter.values.to = null; */
        filter.values = {from: null, to: null};
      }

    }

    filter._clearable = false;
  
    this.setFilterSelectedOptions(filter);
    this.setFilterValueLabel(filter);
    
    if (!batch) {
      this.setFiltersDisabled();
      this.filtersSaveLocal();
    }
    
    if (!useDefaults && !batch) {
      this.getData();
    }
  }

  private async clearAllFilters() {
    for (const [key] of Object.entries(this.filters)) {
      await this.filterClear(this.filters[key], false, true);
    }
    this.setFiltersDisabled();
    this.filters.searchText = null;
    this.filters.skip = 0;
    this.filtersSaveLocal();
    this.getData();
  }

  private async filterReset(filter) {
    if (filter.defaultValues) {
      if (typeof filter.defaultValues === 'function') {

        filter.values = await filter.defaultValues();
      } else {
        filter.values = filter.defaultValues;
      }
    } else {
      this.filterClear(filter);
    }
  }

  private filterGetSelectedOptions(filter) {
    return filter.options.filter((option) => {
      return (filter.values[option.Id]);
    });
  }

  private setFilterInputFocus(filter, state: boolean) {
    setTimeout(() => {
      this.filterInputInFocus[filter.name] = state;
    });
  }

  private filterSearchKeydown(filter, event) {
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode == 27) {
      filter._search = null;
    }
    return true;
  }

  private filterToggleVisible(filter, event) {
    filter._visible = event.target.checked;
    this.filterClear(filter); // Will also trigger filtersSaveLocal
  }

  private filtersSaveLocal() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.filters)) {
      
      const filter = this.filters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false
        };
      }
    }
    
    localStorage.setItem(this.getKey(), JSON.stringify(data));
  }

  public setupFilters() {

    return new Promise(async(resolve, reject) => {

      interface Filters {
        [key: string]: {

          /**
           * Name of the servicefilter
           */
          name: string;
    
          /**
           * The label that is used in frontend.
           */
          label: string;
    
          /**
           * Type of servicefilter. Should reference a constant from filterTypes.
           */
          type: string;
          
          /**
           * Options to use with the servicefilters. Can be an array, async function or normal function. Must return array.
           * If using RADIO_SIMPLE-type, a string can also be returned.
           */
          options?: Array<any> | string;

          /**
           * Used to reference the options-function. Used to refresh options.
           */
          optionsFunc?: any;
    
          /**
           * Defines how this servicefilter should be handled when creating query.
           * Accepts both `string` and `function`.
           * 
           * If `string`: will use this field as key on query and will use servicefilter's `values` as value.
           * 
           * If `function`: the function must return an object containing `key` and `value`.
           */
          query?: string | {
            (values?: object): {
              key: string;
              value: string;
            } | Array<{
              key: string;
              value: string;
            }>
          };
    
          /**
           * This fields contains all set values for the servicefilter.
           */
          values?: string | object | Array<any> | boolean;

          /**
           * Sets default value for the servicefilter.
           */
          defaultValues?: string | object | Array<any> | any;

          /**
           * Creates a custom clear-function instead if default. Does not return anything.
           */
          clear?: { (values?: object) };

          /**
           * Used to make filters conditional based on other filters or values.
           */
          disabled?: { (filters?: object) } | boolean | void | any;

          /**
           * Label to show when filter is disabled.
           */
          disabledLabel?: string;

          /**
           * Used as an array-version of values. Mostly used in the frontend.
           */
          _selectedOptions?: any;

          /**
           * Used to set a filter to disabled.
           */
          _disabled?: any;

          /**
           * Helper for frontend to keep dropdown open when an related element - i.e. the datepicker - is open.
           * The datepicker-element is not a child of the servicefilter, and will use this variable to keep the state.
           */
          _childInFocus?: { from: boolean, to: boolean };

          /**
           * Used by localstorage and frontend to show/hide filters from list.
           */
          _visible?: boolean;

          /**
           * Used in view to show/hide the clear-button.
           */
          _clearable?: boolean;

          dropdownRef?: HTMLElement

        };
      }

      /* 
        GET OPTIONS FOR FILTERS
      */
      const customerIdsOptions: any = async() => {
        const data: any = await this.customerService.getAll();
        return data.sort((a, b) => a.Name > b.Name ? 1 : -1);
      }

      /*
        SERVICEFILTERS
      */
      const filters: Filters = {

        /* Kunde */
        customerIds: {
          name: 'customerIds',
          label: this.i18n.tr('general.customer'),
          type: this.filterTypes.CHECKBOX,
          options: await customerIdsOptions(),
          query: 'customerIds',
          clear: () => {
            this.filterClear(this.filters.customerIds, true);
            this.filterClear(this.filters.receivedFromSiteId, true);
            
            if (this.context === 'customer-services' || this.context === 'net-services') {
              filters.customerIds.values = {[this.customerId]: true};
            }
          }
        },

        /* Periode */
        invoiced: {
          name: 'invoiced',
          label: this.i18n.tr('filters.invoiced'),
          type: this.filterTypes.RANGE_DATE,
          defaultValues: () => {            
            return {from: null, to: null};            
          }
        },

      };

      // Function for refreshing options-list
      // Used with filter-disabling
      this.eventAggregator.subscribe('filterOptionsRefresh', async (filterKey) => {
        this.filters[filterKey].options = await filters[filterKey].optionsFunc();
        this.setFilterValueLabel(this.filters[filterKey]);
      });
    
      // Functions and stuff to do when loading filters for the first time
      const filtersListLocal = JSON.parse(localStorage.getItem(this.getKey()));
      
      // Default filters visible
      if (!filtersListLocal) {
        filters.customerIds._visible = true;
        filters.invoiced._visible = true;
      }

      for (const [key, filter] of Object.entries(filters)) {

        // Set default values and helpers for filters
        if (!filter.values) {
          if (filters[key].type === this.filterTypes.CHECKBOX) {
            filters[key].values = {};
            filters[key]._selectedOptions = [];
          }
    
          if (filters[key].type === this.filterTypes.RADIO) {
            filters[key].values = null;
          }

          if (filters[key].type === this.filterTypes.RANGE) {
            filters[key].values = { from: null, to: null };
          }

          if (filters[key].type === this.filterTypes.RANGE_DATE) {
            filters[key].values = { from: null, to: null };
            filters[key]._childInFocus = { from: false, to: false };
          }

          if (filters[key].type === this.filterTypes.RADIO_SIMPLE) {
            filters[key].values = null;
            
            // Helper for RADIO_SIMPLE-filter, so we don't have to write
            // options for show and hide all the times
            if (typeof filters[key].options === 'string') {
              filters[key].options = [
                { label: this.i18n.tr('general.show'), value: null },
                { label: this.i18n.tr('general.hide'), value: false },
                { label: filters[key].options, value: true }
              ];
            }

          }

        }

        // Set values to default values, if any
        if (filters[key].defaultValues) {          
          if (typeof filters[key].defaultValues === 'function') {
            filters[key].values = await filters[key].defaultValues();
          } else {
            filters[key].values = filters[key].defaultValues;
          }
        }

        // Handling localstorage - sets values and visible-state
        if (filtersListLocal) {
          if (filtersListLocal[key]) {
            filters[key]._visible = filtersListLocal[key].visible === undefined ? true : filtersListLocal[key].visible;
            filters[key].values = filtersListLocal[key].values;
          }
        }

        // Show/hide clear-button
        if (filters[key].type === this.filterTypes.CHECKBOX) {
          filters[key]._clearable = Object.values(filters[key].values).includes(true);

          if (filters[key].defaultValues && typeof filters[key] === 'object') {
            const isDefault = Object.entries(filters[key].values).every(([valueKey, valueValue]) => !valueValue || filters[key].defaultValues[valueKey] == valueValue);
            if (isDefault) {
              filters[key]._clearable = false;
            }
          }

        }
  
        if (filters[key].type === this.filterTypes.RANGE) {
          filters[key]._clearable = (filters[key].values['from'] || filters[key].values['to']);
        }
  
        if (filters[key].type === this.filterTypes.RANGE_DATE) {
          filters[key]._clearable = (filters[key].values['from'] || filters[key].values['to']);
        }
  
        if (filters[key].type === this.filterTypes.RADIO_SIMPLE) {
          filters[key]._clearable = (filters[key].values !== null);
        }
  
        if (filters[key].type === this.filterTypes.RADIO) {
          filters[key]._clearable = (filters[key].values !== null);
        }

        // Set selected options (works for checkboxes)
        this.setFilterSelectedOptions(filters[key]);

        // Sets value-label
        this.setFilterValueLabel(filters[key]);

      }


      if (filtersListLocal) {
        if (filtersListLocal.orderBy) {
          filters.orderBy = filtersListLocal.orderBy;
        }
        if (filtersListLocal.orderByDirection) {
          filters.orderByDirection = filtersListLocal.orderByDirection;
        }
        if (filtersListLocal.skip) {
          filters.skip = filtersListLocal.skip;
        }
        if (filtersListLocal.top) {
          filters.top = filtersListLocal.top;
        }
        if (filtersListLocal.searchText) {
          filters.searchText = filtersListLocal.searchText;
        }
      }

      this.filters = filters;

      return resolve(true);
    });

  }

  private filterMouseEnter(filter) {
    this.setFilterInputFocus(filter, true);
    
    if (filter.type === this.filterTypes.RANGE_DATE && filter._dropdownRef) {
      const left = window.scrollX + filter._dropdownRef.getBoundingClientRect().left;
      const width = 477; // Width of datepicker-filter
      const isOverflowing = (left + width) > window.innerWidth ? true : false;
      if (isOverflowing) {
        filter._dropdownRef.classList.add('servicefilter-dropdown--datefilter-overflow-fix');
      } else {
        filter._dropdownRef.classList.remove('servicefilter-dropdown--datefilter-overflow-fix');
      }
    }

  }

}
